<script setup lang="ts">
import type { Locale } from 'lc-services/types'

const Route = useRoute()
const router = useRouter()
const { locale } = useI18n<unknown, Locale>()
const localePath = useLocalePath()
const { trackEvent } = useTracking()
const { isRouteMatched, headerTheme, setNextRoute } = useHeaderNavigation()
const { headerDesktop, closeDropdown, setActiveDropdown } = useHeaderDesktop()

const currentDropdown = computed(() => headerDesktop.value.activeDropdown)
const tabUnderlined = computed(() => headerDesktop.value.tabUnderlined)

const fontWeightStyle = (routeName: string) => {
  return isTabActive(routeName) || isRouteMatch(routeName)
    ? 'font-bold'
    : 'font-normal'
}
const onLinkClicked = (route: typeof Route) => {
  // if dropdown already open, trigger async navigation
  // else push directly
  if (currentDropdown.value.name && route.name === Route.name) {
    closeDropdown()
  } else if (currentDropdown.value.name) {
    setNextRoute(route)
  } else {
    router.push(route)
  }

  if (String(route.name ?? '').slice(0, -5) === 'search') {
    trackEvent({
      category: 'Header',
      event: 'search_started',
      source: 'header - find a house',
    })
  }
}
const isRouteMatch = (page: string) => {
  const route = {
    locale: locale.value,
    page,
    path: Route.path,
  }

  return isRouteMatched(route)
}
const isTabActive = (tabName: string) => tabUnderlined.value === tabName

const toggleDropdown = (
  name: 'destinations' | 'inspirations' | 'about',
  index: number,
) => {
  setActiveDropdown(name, index)

  const mappingTracking = {
    destinations: 'destinations',
    inspirations: 'inspirations',
    about: 'about-us',
  }

  trackEvent({
    event: 'header_clicked',
    item: mappingTracking[name],
  })
}
</script>

<template>
  <nav
    class="navigation-links-desktop"
    :aria-label="$t('header.mainNavigation')"
  >
    <ul class="flex items-baseline m-0">
      <li class="text-center">
        <NuxtLink
          v-slot="{ href, isActive, route }"
          :to="localePath('search')"
          custom
        >
          <a
            id="header-houses"
            :class="[
              'lc-link navigation-links-desktop__btn',
              { 'font-bold active': isActive },
              headerTheme === 'white' ? 'black' : 'white',
            ]"
            data-testid="navigation-links-desktop-link"
            :href="href"
            @click.prevent="onLinkClicked(route)"
          >
            {{ $t('header.discoverOurHouses') }}
          </a>
        </NuxtLink>
      </li>
      <li class="ml-6 text-center">
        <base-button
          id="header-destinations"
          :aria-expanded="currentDropdown.name ? true : false"
          :class="[
            'navigation-links-desktop__btn',
            { active: isTabActive('destinations') },
          ]"
          typo="md"
          :color="headerTheme === 'white' ? 'black' : 'white'"
          :font-weight="fontWeightStyle('destinations')"
          aria-controls="header-dropdown"
          no-padding
          role="menu"
          @click="toggleDropdown('destinations', 0)"
        >
          {{ $t('header.destinations') }}
        </base-button>
      </li>
      <li class="ml-6 text-center">
        <base-button
          id="header-inspirations"
          :aria-expanded="currentDropdown.name ? true : false"
          :class="[
            'navigation-links-desktop__btn',
            { active: isTabActive('inspirations') },
          ]"
          typo="md"
          :color="headerTheme === 'white' ? 'black' : 'white'"
          :font-weight="fontWeightStyle('inspirations')"
          aria-controls="header-dropdown"
          no-padding
          role="menu"
          @click="toggleDropdown('inspirations', 1)"
        >
          {{ $t('header.inspirations') }}
        </base-button>
      </li>
      <li class="ml-6 text-center">
        <base-button
          id="header-about"
          :aria-expanded="currentDropdown.name ? true : false"
          :class="[
            'navigation-links-desktop__btn',
            { active: isTabActive('about') },
          ]"
          typo="md"
          :color="headerTheme === 'white' ? 'black' : 'white'"
          :font-weight="fontWeightStyle('about')"
          aria-controls="header-dropdown"
          no-padding
          role="menu"
          @click="toggleDropdown('about', 2)"
        >
          {{ $t('header.aboutUs') }}
        </base-button>
      </li>
    </ul>
  </nav>
</template>

<style>
.navigation-links-desktop__btn {
  letter-spacing: 0.07ex; /* equivalent to bold width */
}

.navigation-links-desktop__btn.active {
  letter-spacing: 0;
}

.lc-link.black {
  @apply text-gray-700 focus:text-gray-700 hover:text-gray-700 hover:no-underline;
}

.lc-link.white {
  @apply text-white focus:text-white hover:text-white hover:no-underline;
}
</style>
