import type { Locale } from 'lc-services/types'

export const formatDate = (date: string, format = 'YYYY-MM-DD') =>
  $dayjs(date, 'YYYY-MM-DD').format(format)

export const formatTime = (time: string, locale: Locale) => {
  if (locale === 'fr') return time.split(':').join('h')
  const [hour, minute] = time.split(':').map(Number)
  const meridiem = hour / 12 >= 1 ? ' PM' : ' AM'
  if (hour === 0 || hour === 12)
    return `12:${String(minute).padStart(2, '0')}${meridiem}`
  return [hour % 12, String(minute).padStart(2, '0')].join(':').concat(meridiem)
}

export const convertTimeByTimezone = ({
  time,
  locale,
}: {
  time: string
  locale: Locale
}) => {
  const parisTimeZone = 'Europe/Paris'
  const currentTime = new Date()

  const parisTime = new Intl.DateTimeFormat('en-US', {
    timeZone: parisTimeZone,
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }).format(currentTime)

  const utcTime = new Intl.DateTimeFormat('en-US', {
    timeZone: 'UTC',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }).format(currentTime)

  const [parisHours] = parisTime.split(':').map(Number)
  const [utcHours] = utcTime.split(':').map(Number)

  const diffBetweenParisAndUTC = (utcHours || 0) - (parisHours || 0)

  const [hours, minutes] = time.split(':').map(Number)

  const now = new Date()
  const utcDate = new Date(
    Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      (hours || 0) + diffBetweenParisAndUTC,
      minutes,
    ),
  )

  return new Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: locale !== 'fr',
  })
    .format(utcDate)
    .replace(':', locale === 'fr' ? 'h' : ':')
}
