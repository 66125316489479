<script setup lang="ts">
const props = defineProps<{
  email: string
  firstname: string
  lastname: string
  modelValue: boolean
  phone: string | null
}>()

defineEmits<{
  'update:model-value': [value: boolean]
}>()

const actions = computed(() => {
  return [
    {
      icon: 'mail',
      keyTrad: 'stayProposal.page.email',
      information: props.email,
      href: `mailto:${props.email}`,
      target: '_self',
    },
    {
      icon: 'whatsappLc',
      keyTrad: 'stayProposal.page.whatsapp',
      information: props.phone,
      href: `https://wa.me/${props.phone}`,
      target: '_blank',
    },
    {
      icon: 'phone',
      keyTrad: 'stayProposal.page.phone',
      information: props.phone,
      href: `tel:${props.phone}`,
      target: '_self',
    },
  ] as const
})
</script>

<template>
  <BaseModalV2
    :model-value="modelValue"
    @update:model-value="$emit('update:model-value', $event)"
  >
    <template #content>
      <div class="mb-10 font-bold">
        {{ $t('stayProposal.page.contactMyAdvisor') }}
      </div>
      <p class="mb-10">
        {{
          $t('stayProposal.page.advisorIsAvailable', {
            sale: `${firstname} ${lastname}`,
          })
        }}
      </p>
      <div class="flex flex-col">
        <BaseButton
          v-for="(action, i) in actions.filter((x) => x.information)"
          :key="i"
          class="group !bg-transparent hover:text-primary-700 w-full flex items-center mb-6 !p-0"
          color="light"
          font-weight="font-normal"
          has-icon
          :aria-label="$t(action.keyTrad)"
          :href="action.href"
          :target="action.target"
        >
          <BaseIcon class="mr-4" :name="action.icon" :size="2" />
          <div class="text-left">
            <div class="font-bold">
              {{ $t(action.keyTrad) }}
            </div>
            <div
              class="text-gray-500 group-hover:text-primary-700 duration-200 ease-in-out"
            >
              {{ action.information }}
            </div>
          </div>
        </BaseButton>
      </div>
    </template>
  </BaseModalV2>
</template>
