<script setup lang="ts">
import type { PageKey } from '~/composables/Header/useHeaderMobile'

type Nav = {
  ariaLabel?: string
  key: PageKey | 'myaccount'
  name: string
  to?: string
}
type NavWithIcon = Nav & { icon: IconPath; to: string }

const { t } = useI18n()
const localePath = useLocalePath()
const { trackEvent } = useTracking()
const { setMenuIsOpen, setSlidePageActive } = useHeaderMobile()

const { authenticated } = useAuth()

const nav = ref<Nav[]>([
  {
    key: 'search',
    to: 'search',
    name: t('header.findHouse'),
  },
  {
    key: 'destinations',
    to: 'destinations',
    name: t('header.ourDestinations'),
  },
  {
    key: 'inspirations',
    name: t('header.inspirations'),
  },
  {
    key: 'aboutUs',
    name: t('header.aboutUs'),
  },
])

const navWithIcon = computed<NavWithIcon[]>(() => [
  {
    key: 'myaccount',
    to: accountLink.value,
    name: t('header.account'),
    icon: 'profileCircle',
  },
])

// Computed
const accountLink = computed(() =>
  authenticated.value ? localePath('myaccount-menu') : localePath('login'),
)

// Methods
const clickNav = (key: PageKey | 'myaccount') => {
  if (key === 'destinations' || key === 'inspirations' || key === 'aboutUs') {
    setSlidePageActive(key)

    const mappingTracking = {
      destinations: 'destinations',
      inspirations: 'inspirations',
      aboutUs: 'about-us',
    }

    trackEvent({
      event: 'header_clicked',
      item: mappingTracking[key],
    })
  } else {
    if (key === 'search') {
      trackEvent({
        category: 'Navigation',
        event: 'search_started',
        source: 'header - find a house',
      })
    } else if (key === 'myaccount') {
      trackEvent({
        event: 'header_clicked',
        item: 'account',
      })
    }

    setMenuIsOpen(false)
    setSlidePageActive(null)
  }
}
</script>

<template>
  <nav class="flex flex-col w-full h-full px-10 py-32">
    <BaseButton
      v-for="link in nav"
      :key="link.name"
      :to="link.to ? localePath(link.to) : null"
      class="my-5 tablet:my-8 tracking-widest uppercase !flex items-center !text-left"
      color="black"
      font-weight="font-normal"
      no-padding
      @click="clickNav(link.key)"
    >
      {{ link.name }}
    </BaseButton>

    <span class="block border-t border-gray-200 w-3/5 my-5" />

    <BaseButton
      v-for="link in navWithIcon"
      :key="String(link.key)"
      :to="link.to"
      class="my-5 tracking-widest uppercase !flex items-center !text-left"
      color="black"
      font-weight="font-normal"
      no-padding
      @click="clickNav(link.key)"
    >
      <BaseIcon :name="link.icon" class="mr-4" />
      {{ link.name }}
    </BaseButton>
  </nav>
</template>
