<script setup lang="ts">
import type { Locale } from 'lc-services/types'

withDefaults(
  defineProps<{
    canClose?: boolean
  }>(),
  {
    canClose: false,
  },
)
const emits = defineEmits<{ close: [] }>()

const { $api } = useNuxtApp()
const { t } = useI18n<unknown, Locale>({ useScope: 'local' })
const { user, modifyUserInformations } = useUser()
const { isMobile } = useBreakpoint()

const [travelAgentRentalConditions, travelAgentSignature] = await Promise.all([
  $api.v1.travelAgentGeneralRentalCondition
    .read()
    .then((res) => res.data)
    .catch(() => ({ content: '', id: '' })),

  $api.v3.userTravelAgentGeneralRentalCondition
    .read()
    .then((res) => res.data)
    .catch(() => ({ id: '' })),
])

const modelValue = ref(
  !user.value.travelAgentGeneralRentalConditionSignedAt ||
    travelAgentRentalConditions?.id !== travelAgentSignature?.id,
)

const acceptCGP = async () => {
  await modifyUserInformations({
    user: {
      travel_agent_general_rental_condition_signed_at: $dayjs().format(
        'YYYY-MM-DD HH:mm:ss',
      ),
    },
  })
  modelValue.value = false
}
const close = () => {
  emits('close')
  modelValue.value = false
}
</script>

<template>
  <BaseModalV2
    v-if="travelAgentRentalConditions?.content"
    data-cy="modal-partner-conditions"
    required-reading
    size="m"
    :has-close-button="canClose"
    :model-value="modelValue"
    :primary-button="canClose ? '' : t('accept')"
    @close="close"
    @primary-button="acceptCGP"
  >
    <template #content>
      <div class="flex flex-col gap-4 pr-4 pb-4">
        <h2 class="text-2xl font-bold m-0 mr-2">{{ t('title') }}</h2>
        <div v-html="travelAgentRentalConditions.content" />
        <div
          class="flex gap-2 items-start bg-primary-100 px-4 py-3 mb-8 sm:mb-0"
        >
          <BaseIcon v-if="!isMobile" :size="2" name="infoCircle" />
          <p class="flex-grow m-0">{{ t('info') }}</p>
        </div>
      </div>
    </template>
  </BaseModalV2>
</template>

<i18n lang="yaml">
fr:
  title: Nous avons mis à jour nos Conditions Générales de Partenariat pour Agents de Voyages
  info: En acceptant nos conditions, vous aurez accès à la barre de recherche dédiée aux agents, vous offrant davantage d'informations sur les propriétés.
  accept: J'ai lu et j'accepte
en:
  title: We have updated our General Partnership Conditions for Travel Agents
  info: By agreeing to these terms you will have access to the dedicated  search bar for agents, providing you with more information on properties.
  accept: Accept and agree
</i18n>
