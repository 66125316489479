<script setup lang="ts">
import type { RouteLocation } from 'vue-router'

const emits = defineEmits<{
  'on-logo-click': []
}>()

const router = useRouter()
const localePath = useLocalePath()
const { headerMobile, setSearchIsOpen } = useHeaderMobile()
const { headerDesktop } = useHeaderDesktop()
const { headerTheme, setNextRoute } = useHeaderNavigation()
const { trackEvent } = useTracking()

const currentDropdown = computed(() => headerDesktop.value.activeDropdown)
const menuMobileIsOpen = computed(() => headerMobile.value.menuIsOpen)
const searchIsOpen = computed(() => headerMobile.value.searchIsOpen)

const onLinkClicked = (route: RouteLocation) => {
  emits('on-logo-click')

  trackEvent({
    event: 'header_clicked',
    item: 'logo',
  })

  // if dropdown already open, trigger async navigation
  // else push directly
  if (currentDropdown.value.name) {
    setNextRoute(route)
  } else {
    router.push(route)
  }

  if (searchIsOpen.value) setSearchIsOpen(false)
}
</script>

<template>
  <div
    :class="[
      'logo',
      { 'logo-white': !menuMobileIsOpen && headerTheme === 'transparent' },
    ]"
  >
    <NuxtLink v-slot="{ href, route }" :to="localePath('index')" custom>
      <a
        data-testid="header-logo-link"
        :href="href"
        @click.prevent="onLinkClicked(route)"
      >
        <BaseNuxtImg
          :alt="$t('header.logoAltText')"
          src="production/assets/images/lecollectionist-luxury-villas-rentals-holidays.svg"
          width="188"
          height="24"
          fit="contain"
        />
      </a>
    </NuxtLink>
  </div>
</template>

<style>
.logo img {
  height: 24px;
  min-width: 188px;
  display: block;
  transition: filter 0.3s ease-in;
}

.logo-white img {
  filter: invert(1);
  transition: filter 0.2s ease-in;
}
</style>
